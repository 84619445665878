import http from "../../http-common";
export const mercadopagoConnect = async (data) => {
  return await http
    .get(`/api/mercadopago/connect`, {
      params: {
        code: data.code,
        state: data.state,
      },
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
export const courtesy = async (id, accepted) => {
  return await http
    .post(`/api/courtesy`, {
      id,
      accepted,
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
